<div class="modal-header">
  <h4 class="modal-title text-body text-center">{{'partials.signUp.importKeystore.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input (change)="onFileChange($event)" [ngClass]="{'is-invalid': fileErr}" type="file" class="form-control mb-3">
  <app-show-hide-input-text>
    <input [(ngModel)]="keystorePassword" [ngClass]="{'is-invalid': keystorePasswordErr}" type="password"
           class="form-control"
           [placeholder]="'partials.signUp.importKeystore.keystorePassword' | translate">
  </app-show-hide-input-text>
  <div *ngIf="importKeystoreErrText" [innerHTML]="importKeystoreErrText" class="invalid mb-2">
  </div>
</div>
<div class="modal-footer justify-content-center">
  <app-spinner-button (click)="login()" [options]="importBtn">
    {{ 'partials.login.login' | translate }}
  </app-spinner-button>
</div>

