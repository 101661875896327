<div class="modal-header">
  <h4 class="modal-title text-body text-center">{{'partials.signUp.newAccount.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="step == 'newPassword'" id="set-password">
    <app-show-hide-input-text>
      <input [(ngModel)]="password" [ngClass]="{'is-invalid': passwordErr}" type="password"
             class="form-control" [placeholder]="'partials.signUp.newAccount.password' | translate">
    </app-show-hide-input-text>
    <app-show-hide-input-text>
      <input [(ngModel)]="repeatPassword" [ngClass]="{'is-invalid': repeatPasswordErr}"
             type="password" class="form-control"
             [placeholder]="'partials.signUp.newAccount.repeatPassword' | translate">
    </app-show-hide-input-text>

    <div *ngIf="passwordErrText" class="invalid mb-2">{{passwordErrText}}</div>

    <p class="text-justify">
      <small [innerHTML]="'partials.signUp.newAccount.rememberPassword' | translate">
      </small>
    </p>

  </div>
  <div *ngIf="step == 'walletInfo'">
    <app-wallet-info></app-wallet-info>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <div *ngIf="step == 'newPassword'">
    <button (click)="createWallet();" type="button"
            class="btn btn-primary new-account-btn float-left">
      {{'partials.signUp.newAccount.generate' | translate}}
    </button>
  </div>
  <div *ngIf="step == 'walletInfo'">
    <button (click)="login();activeModal.close('Close click')" type="button" class="btn btn-primary">
      {{'partials.login.login' | translate}}
    </button>
  </div>
</div>

