import {Component, Input, OnInit, ApplicationRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../../core/utils/utils.service';
import {EthersService} from '../../../core/ethers/services/ethers.service';
import * as qs from 'querystring';


@Component({
  selector: 'app-certificate-modal',
  templateUrl: './certificate-modal.component.html',
  styleUrls: ['./certificate-modal.component.scss']
})
export class CertificateModalComponent implements OnInit {
  @Input() public certificate;


  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private utils: UtilsService,
    private appRef: ApplicationRef,
    private ethersService: EthersService
  ) {
  }

  ngOnInit(): void {
    // console.log(this.certificate, 'certificate!!');
  }

  download() {
    const certificate = this.certificate;
    delete certificate.network;
    delete certificate.contract;
    delete certificate.tx;
    // console.log(certificate, 'certificate');
    const blob = this.utils.b64toBlob(btoa(JSON.stringify(certificate, null, '  ')), 'application/json');
    const blobUrl = URL.createObjectURL(blob);
    const fileName = this.certificate.fileName ? this.certificate.fileName.split('.')[0] : this.certificate.fileHash;
    this.utils.downloadFile(fileName + '_certificate.json', blobUrl);
  }

  async print() {

    if (this.ethersService.wallet && !this.ethersService.asymmetricAccount?.publicKey) {
      try {
        await this.ethersService.importAsymmetricWallet();
      } catch (e) {
      }
    }

    const params = encodeURI(JSON.stringify(this.certificate));
    const mywindow = window.open('./print-certificate?encodedCertificate=' + params, 'PRINT', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    // necessary for IE >= 10*/


    // mywindow.print();
    // setTimeout(() => {
    //
    //   mywindow.close();
    //   mywindow.focus();
    // }, 1000);


    // mywindow.print();
    //
    // setTimeout(() => {
    //   mywindow.close();
    // }, 3000);
    // mywindow.close();
  }


  serialize(obj, prefix?) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        str.push((v !== null && typeof v === 'object') ?
          this.serialize(v, k) :
          encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }
}
