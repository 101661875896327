import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EthersService} from '../../../core/ethers/services/ethers.service';

@Component({
  selector: 'app-sign-message-box',
  templateUrl: './sign-message-box.component.html',
  styleUrls: ['./sign-message-box.component.scss']
})
export class SignMessageBoxComponent implements OnInit {

  constructor(
    public modalService: NgbModal,
    private ethersService: EthersService
  ) { }

  ngOnInit(): void {
  }

  sign() {
    this.ethersService.importAsymmetricWallet();
  }

}
