import {AfterViewInit, ApplicationRef, Component, Input, OnInit} from '@angular/core';
import {ContractsDatatablesService} from '../../../core/contracts/contracts-datatables.service';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ContractsService} from '../../../core/contracts/contracts.service';
import {EthersService} from '../../../core/ethers/services/ethers.service';

@Component({
  selector: 'app-shipping-data-modal',
  templateUrl: './shipping-data-modal.component.html',
  styleUrls: ['./shipping-data-modal.component.scss']
})
export class ShippingDataModalComponent implements AfterViewInit {
  // data: any = {}
  verified: boolean = false;
  verifying: boolean = true;
  name: string = '';
  address: string = '';
  city: string = '';
  country: string = '';
  phoneNumber: string = '';
  email: string = '';
  submitted = false;
  @Input() status: string = 'pending';
  @Input() tokenId: number = 0;
  @Input() smartContractOwner: string = '';
  @Input() contractAddress: string = '';
  @Input() signature: string = '';
  @Input() data: any;
  @Input() dataString: any;
  @Input() chainId: any;

  constructor(
    public contractsDatatables: ContractsDatatablesService,
    public contractsService: ContractsService,
    public modal: NgbModal,
    public ethersService: EthersService,
    private appRef: ApplicationRef,
  ) {
  }

  ngAfterViewInit(): void {
    this.ethersService.getTokenOwner(this.tokenId, this.contractAddress, this.chainId)
      .then((tokenOwner) => {
        const hashedData = this.ethersService.hashMessage(this.dataString);
        // const signedData = ethersService.signMessage(hashedData);
        const walletFromHash = this.ethersService.verifyMessage(hashedData, this.signature);

        this.name = this.data.name;
        this.address = this.data.address;
        this.city = this.data.city;
        this.country = this.data.country;

        if (this.data.phoneNumber === undefined) this.phoneNumber = '';
        else this.phoneNumber = this.data.phoneNumber;

        if (this.data.email === undefined) this.email = '';
        else this.email = this.data.email;

        this.verifying = false
        /*console.log(walletFromHash, "walletFromhash");
        console.log(tokenOwner, "tokenOwner");*/
        if (walletFromHash === tokenOwner) {
          this.verified = true;
          this.appRef.tick();
        } else {
          this.verified = false;
          this.appRef.tick();
        }
      });
  }


  acceptForm() {
    this.contractsDatatables.setStatus(this.tokenId, this.contractAddress, 'accepted');
    this.savedAcceptAlert();
    console.log('accept');
  }

  rejectForm() {
    this.contractsDatatables.setStatus(this.tokenId, this.contractAddress, 'declined');
    this.savedDenyAlert();
    console.log('reject');
  }

  sendForm() {
    this.contractsDatatables.setStatus(this.tokenId, this.contractAddress, 'sent');
    this.sendAlert();
    console.log('sent');
  }

  deleteForm() {
    this.contractsService.delContract(this.tokenId);
  }

  savedAcceptAlert() {
    Swal.fire({
      // position: 'top-end',
      icon: 'success',
      title: 'You have accepted.',
      showConfirmButton: false,
      timer: 2500
    }).then(() => {
      this.contractsDatatables.refreshDatatables();
      this.modal.dismissAll();
    });
  }

  sendAlert() {
    Swal.fire({
      // position: 'top-end',
      icon: 'success',
      title: 'The NFT has been sent.',
      showConfirmButton: false,
      timer: 2500
    }).then(() => {
      this.contractsDatatables.refreshDatatables();
      this.modal.dismissAll();
    });
  }

  savedDenyAlert() {
    Swal.fire({
      // position: 'top-end',
      icon: 'success',
      title: 'You have rejected.',
      showConfirmButton: false,
      timer: 2500
    }).then(() => {
      this.contractsDatatables.refreshDatatables();
      this.modal.dismissAll();
    });
    ;
  }
}
