<div class="modal-header">
  <h4 class="modal-title text-body text-center">{{'partials.connect.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div id="connect-menu" class="modal-body">
   <button (click)="metamaskConnect()" class="btn btn-lg btn-light btn-block mb-2 text-left">Metamask <img class="float-right" src="assets/img/connect/MetaMask.png"></button>
<!--   <button class="btn btn-light btn-block mb-2 text-left">Trezor <img class="float-right" src="assets/img/connect/trezor.png"></button>-->
  <button (click)="showWebWalletSignUp()" class="btn btn-lg btn-light btn-block mb-2 text-left">{{'partials.connect.webWallet' | translate}} <i class="float-right fas fa-wallet"></i></button>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">{{'generic.close' | translate}}</button>
</div>
