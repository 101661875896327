import {Component, OnInit} from '@angular/core';
import {EthersService} from '../../../core/ethers/services/ethers.service';
// import {WalletService} from '../../../core/services/wallet.service';
// import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-wallet-info',
  templateUrl: './wallet-info.component.html',
  styleUrls: ['./wallet-info.component.scss']
})
export class WalletInfoComponent implements OnInit {

  wallet: any;
  decodePassword: string;

  constructor(
    // private authService: AuthenticationService,
    public walletService: EthersService
  ) {
  }

  ngOnInit() {
    // this.wallet = this.walletService.wallet;
    this.walletService.$wallet.subscribe(() => {
      if (this.walletService.connectMethod === 'METAMASK') {
        this.wallet = {
          address: this.walletService.wallet
        };
      } else {
        this.wallet = this.walletService.webWallet;

      }

      // console.log(this.wallet, 'this.wallet');
    });
    this.decodePassword = this.walletService.decodePass;
  }
}
