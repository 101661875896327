import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WalletInfoComponent} from './wallet-info.component';
import {ShowHideInputTextModule} from '../show-hide-input-text/show-hide-input-text.module';
import {FormsModule} from '@angular/forms';
import {QRCodeModule} from 'angularx-qrcode';
import {AppModule} from '../../../app.module';
import {TranslateModule} from '@ngx-translate/core';
import {DownloadKeystoreComponent} from '../download-keystore/download-keystore.component';
import {SpinnerButtonModule} from '../spinner-button/spinner-button.module';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [WalletInfoComponent, DownloadKeystoreComponent],
  imports: [
    SpinnerButtonModule,
    CommonModule,
    ShowHideInputTextModule,
    FormsModule,
    QRCodeModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    WalletInfoComponent, DownloadKeystoreComponent
  ]
})
export class WalletInfoModule { }
