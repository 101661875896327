<div class="modal-body bg-primary text-white text-center">
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row justify-content-md-center" *ngIf="certificate">
    <div class="col-md-8">
      <h2 style="line-height: 1;"
          class="text-white mb-0 mt-3 font-weight-light d-none">{{'partials.certificateModal.document' | translate}}</h2>
      <h1 style="line-height: 1;"
          class="text-white font-weight-bold">{{'partials.certificateModal.document' | translate}} {{'partials.certificateModal.certificate' | translate}}</h1>
      <img style="max-width: 100px" class="mx-auto my-4" src="assets/img/certificate-icon.png" alt="">
      <p *ngIf="certificate.fileName">{{certificate.fileName ? certificate.fileName : ''}}</p>
      <p><b>{{'partials.certificateModal.fileHash' | translate}}</b>
        <br>{{certificate.fileHash}}</p>
      <ng-container *ngFor="let network of certificate.networks">
        <hr class="text-white">

        <p *ngIf="network.owner">
          <b>{{'partials.certificateModal.owner' | translate}}</b>
          <br *ngIf="[network.company, network.fullname, network.identityCard, network.country] | joinArray : ' - '">
          {{[network.company, network.fullname, network.identityCard, network.country] | joinArray : ' - '}}
          <br *ngIf="network.ownerAddressENS">{{network.ownerAddressENS ? network.ownerAddressENS : ''}}
          <br>{{network.owner}}

        </p>

        <p *ngIf="network.network && network.contract"><b>{{'partials.certificateModal.contract' | translate}}</b>
          <br>
          {{network.contract}} {{network.network ? '(' + network.network.toUpperCase() + ')' : ''}}</p>
        <p *ngIf="network.tx"><b>{{'partials.certificateModal.transaction' | translate}}</b>
        <br>{{network.tx}}</p>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary" (click)="print()"
	>{{'generic.print' | translate}}</button>
  <button type="button" class="btn btn-primary" (click)="download()">{{'generic.download' | translate}}</button>
  <button type="button" class="btn btn-dark"
          (click)="activeModal.close('Close click')">{{'generic.close' | translate}}</button>
</div>
