<div class="modal-header">
  <h4 class="modal-title text-body">{{'partials.sendEmailModal.email' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="email-from">{{'partials.sendEmailModal.from' | translate}}</label>
      <input [(ngModel)]="this.from" [ngModelOptions]="{standalone: true}" type="email" class="form-control" id="email-from" placeholder="name@example.com">
    </div>
    <div class="form-group">
      <label for="email-to">{{'partials.sendEmailModal.to' | translate}} <span class="text-danger">*</span></label>
      <input [(ngModel)]="this.to" [ngModelOptions]="{standalone: true}"  type="email" class="form-control" id="email-to" placeholder="name@example.com">
    </div>
    <div class="form-group">
      <label for="message">{{'partials.sendEmailModal.message' | translate}}</label>
      <textarea [(ngModel)]="this.message" [ngModelOptions]="{standalone: true}"  class="form-control" id="message" rows="3"></textarea>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-center">

  <button type="button" class="btn btn-primary" (click)="sendEmail()">{{'partials.sendEmailModal.send' | translate}}</button>
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">{{'generic.close' | translate}}</button>
</div>

