import {Component, OnInit} from '@angular/core';
import {EthersService} from '../../../../core/ethers/services/ethers.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../login/login.component';

declare var $: any;

@Component({
  selector: 'app-import-wallet',
  templateUrl: './import-wallet.component.html',
  styleUrls: ['./import-wallet.component.scss']
})
export class ImportWalletComponent implements OnInit {
  file: any;
  keystorePassword: string;

  keystorePasswordErr: boolean;
  fileErr: boolean;
  importKeystoreErrText: string;

  importBtn = {
    loading: false
  };

  constructor(
    public activeModal: NgbActiveModal,
    private walletService: EthersService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.file = '';
    this.keystorePassword = '';
    this.fileErr = false;
    this.keystorePasswordErr = false;
    this.importKeystoreErrText = '';
  }


  onFileChange(e) {
    this.file = e.target.files[0];
  }

  login() {
    if (this.file && this.keystorePassword) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.importBtn.loading = true;
        const wallet = await this.walletService.importKeystoreWallet(reader.result, this.keystorePassword);
        if (wallet) {
          this.activeModal.close();

          this.modalService.open(LoginComponent);
        } else {
          this.keystorePasswordErr = true;
          this.translateService.get('partials.signUp.importKeystore.errors.errorKeystorePassword').subscribe((errorKeystorePassword) => {
            this.importKeystoreErrText = errorKeystorePassword;
          });
        }
        this.importBtn.loading = false;
      };
      reader.readAsBinaryString(this.file);
    } else {
      this.keystorePasswordErr = true;
      this.fileErr = true;
      this.translateService.get('partials.signUp.importKeystore.errors.errorKeystoreUpload').subscribe((errorKeystoreUpload) => {
        this.importKeystoreErrText = errorKeystoreUpload;
      });
    }
  }

}
