<div class="modal-header">
  <h4 class="modal-title text-body">{{'partials.importQR.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
   <div id="loadingMessage">
          {{'partials.importQR.cameraError' | translate}}
        </div>
        <canvas id="qr-canvas" class="w-100"></canvas></div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">{{'generic.close' | translate}}</button>
</div>
