import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

import Swal from 'sweetalert2';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {EthersService} from '../../../core/ethers/services/ethers.service';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss']
})
export class SendEmailModalComponent implements OnInit {
  @Input() public fileInfo;

  from: string;
  to: string;
  message: string;

  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  ngOnInit(): void {
    if (this.fileInfo.receiversPublicKey && this.fileInfo.receiversPublicKey.length > 0) {
      this.http.get(
        'https://api-nft.zertifier.com/api/contact/' + this.fileInfo.wallet + '/' + this.fileInfo.receiversPublicKey[0]
      ).pipe(first()).subscribe((res: any) => {
        this.to = res.hasOwnProperty('email') ? res.email : '';
      });
    }

  }


  sendEmail() {
    if (this.to) {
      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {
            if (this.fileInfo.receiversPublicKey.length) {
              this.sendEmailRequest(token, false);
            } else {
              this.sendEmailRequest(token);
            }
          },
          error => {
            // console.log(error, 'error');

          });

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html: '"Email to" field are required'
      });
    }
  }


  sendEmailRequest(token, includePassword = true) {
    this.http.post(
      'https://api-nft.zertifier.com/api/send-email-link', {
        ...this.fileInfo,
        from: this.from,
        to: this.to,
        message: this.message,
        token,
        includePassword
      }
    ).pipe(first()).subscribe((res: any) => {
      Swal.fire({
        icon: 'success',
        title: 'Message send',
        html: 'Your message has been successfully send'
      });
      this.activeModal.close();
    });
  }
}
