<div class="container my-4">
  <div class=" d-flex justify-content-between ">
    <div></div>
    <h3>Shipping details</h3>
    <button type="button" class="btn btn-form " aria-label="Close" (click)="modal.dismissAll()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div *ngIf="status !== 'sent'" class="d-flex justify-content-center mt-3">
    <p *ngIf="verifying" class="verify-text">Verifying... <i class="fas fa-spinner fa-pulse ml-3"></i></p>
    <p *ngIf="verified && !verifying" class="verify-text text-success">Data verified on the blockchain
      <i class="fas fa-check"></i>
    </p>
    <p *ngIf="!verified && !verifying" class="verify-text text-danger">Shipping wallet and token owner are NOT the same
      <i class="fas fa-times"></i>
    </p>
  </div>
  <!--  <hr/>-->
  <form class="needs-validation" novalidate>
    <div class="mb-3">
      <label class="form-label">Name</label>
      <input [(ngModel)]="name" [ngModelOptions]="{standalone: true}" type="email"
             class="form-control" [ngClass]="{'is-invalid': submitted && name == ''}" disabled>
      <div class="invalid-feedback">
        Field name is required.
      </div>
    </div>

    <div class="mb-3">
      <div class="row">
        <div class="col">
          <label class="form-label">City</label>
          <input [(ngModel)]="city" [ngModelOptions]="{standalone: true}" type="text"
                 class="form-control" disabled>
          <div class="invalid-feedback">
            Field city is required.
          </div>
        </div>
        <div class="col">
          <label class="form-label">Country</label>
          <input [(ngModel)]="country" [ngModelOptions]="{standalone: true}" type="text"
                 class="form-control" disabled>
          <div class="invalid-feedback">
            Field country is required.
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Address</label>
      <input [(ngModel)]="address" [ngModelOptions]="{standalone: true}" type="text"
             class="form-control" disabled>
      <div class="invalid-feedback">
        Field address is required.
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label class="form-label">Phone Number</label>
        <input [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}" type="text" class="form-control"
               disabled>
        <div class="invalid-feedback">
          Fields phone number or email are required.
        </div>
      </div>
      <div class="col">
        <label class="form-label">Email</label>
        <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="text" class="form-control" disabled>
        <div class="invalid-feedback">
          Fields phone number or email are required.
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <button (click)="deleteForm()" *ngIf="this.status !== 'sent'" type="submit"
              class="btn btn-danger mx-auto">Delete
      </button>
      <button (click)="rejectForm()" *ngIf="this.status !== 'sent'" type="submit"
              class="btn btn-dark mx-auto">Reject
      </button>
      <button (click)="acceptForm()" *ngIf="this.status !== 'sent' && this.status !== 'accepted'
               && verified" type="submit"
              class="btn btn-primary mx-auto">Accept
      </button>
      <button (click)="sendForm()" *ngIf="this.status !== 'sent' && this.status === 'accepted'" type="submit"
              class="btn btn-primary mx-auto">Send
      </button>
      <input type="text" autofocus="autofocus" style="display:none"/>
    </div>
  </form>

</div>
